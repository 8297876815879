<template lang="html">
<div id="" class="justify">
  <div class="search-row">
    <Navbar navbarClass="transparent"></Navbar>
      <v-container grid-list-xs,sm,md,lg,xl>
        <v-layout  wrap justify-center>
          <h1 class="display-3 blog-title">
            Referral Programs
          </h1>
        </v-layout>
    </v-container>
  </div>
  <v-container grid-list-xl>
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10>
        <h2 class="text-xs-center headline about-us">Share your love of Taipei</h2>
      </v-flex><v-flex md8 sm9 xs10 >
        <v-layout  wrap>
          <v-flex xs12>
            <div>
              We have noticed that word of mouth and recommendations from seniors is the best way to find a good room in Taipei. We want to give this a little push in the back by giving a discount to your friends. As an extra benefit, when your friends use your gift, you'll be rewarded with a gift of the same amount.
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">Refer a close friend</h3>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          Do you have some very good friends who come to Taipei next semester? You can give them a special  discount code which will give them {{friendVal}} TW$ direct discount on the checkout. And you'll receive that same amount straight in your pocket! This code is limited to 5 uses and will expire at the start of the next semester.
        </div>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          <v-btn color="primary" :disabled="disabledGenerate" @click="generateCode(friendVal)">Generate your code</v-btn>
        </div>
      <div class="px-2">
          <a href="/ReferralTerms">Terms & Conditions</a>
        </div>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">Social referral program</h3>
      </v-flex>

      <v-flex md8 sm9 xs10>
        <div class="">
          Help future foreigners to find their room by giving them a discount. You'll get rewarded for it. Tell them about MRA and we'll say thank you with a {{socialVal}} TW$ gift for both of you.
        </div>
        <div class="">
          Do you know people who are searching for a new place? Are there students from your university who are coming to Taipei next semester? Are you writing a report or a blog article on accommodation in Taipei? Are people from your country arriving in Taipei and they don’t know where to find accommodation? Do you just want to earn some pocket money?
        </div>
        <div class="">
          You can give them a special  discount code which will give them {{socialVal}} TW$ direct discount on the checkout. And you'll receive that same amount straight in your pocket!
        </div>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          <v-btn color="primary" :disabled="disabledGenerate" @click="generateCode(socialVal)">Generate your code</v-btn>
        </div>
      <div class="px-2">
          <a href="/ReferralTerms">Terms & Conditions</a>
        </div>
      </v-flex>

    </v-layout>

  </v-container>
</div>
</template>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import axios from 'axios'
import * as types from '../../store/types'
import moment from 'moment'
import config from '../../config'


export default {
  name: "",
  data: () => ({
    friendVal:450,
    disabledGenerate:false,
    socialVal:300
  }),
  methods: {
    ...mapActions({
      storeUser: types.STORE_USER
    }),
    generateCode(val) {
      if (this.user.id!=undefined){
        if (this.user.coupon!=undefined){
          alert('you already have a code')
          this.$router.push('/affiliate')
        } else {
          this.disabledGenerate=true
          console.log(this.user.id)
          let code=this.user.userName.replace(/\s/gi, "_").substring(0,4)+Math.floor((1000 + Math.random() * 9000))
          console.log('code: ', code)
          this.user.code=code
          this.storeUser(this.user)
          if (this.user.id<20){
            console.log({roleValue:15, code:code})
            axios.patch('users/'+this.user.id+'.json?auth='+this.$store.state.auth.idToken,{roleValue:15, code:code})
            .then(()=>{
              this.refreshUser()
              this.$router.push('affiliate')
            })
            .catch(err=>{
              console.log("error: ", err);
            })
          } else {
            console.log({code:code});
            axios.patch('users/'+this.user.id+'.json?auth='+this.$store.state.auth.idToken,{code:code})
            .then(()=>{
              this.$router.push('affiliate')
            })
            .catch(err=>{
              console.log("error: ", err);
            })
          }
          this.postCoupon(val,code)
        }
      } else {
        console.log('not logged');
        this.$router.push('login')
      }
    },
    postCoupon(val, name){
      let t={
        name:name,
        val:val,
        expDate:moment().add(6,'Months').format(),
        absVal:true,
        sUse:false,
      }
      console.log(t);
      axios.post('rCoupons.json?auth='+this.$store.state.auth.idToken, t)
      .then(()=>{
        console.log('succes');

      })
      .catch(err=>{
        console.log('error: ',err);
      })
    },
  },
  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
}
.blog-title {
    padding-top: 15vh;
    margin-bottom: 10vh;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.about-us div {
    margin: 1rem 0;
    text-align: justify;
}
.family{
  width:90%;
}
.family {
    width: 90%;
    margin: auto;
}
.justify {
  text-align: justify;
}
.nomargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
</style>
