<template lang="html">
<div id="" class="justify">
  <div class="search-row">
    <Navbar navbarClass="transparent"></Navbar>
      <v-container grid-list-xs,sm,md,lg,xl>
        <v-layout  wrap justify-center>
          <h1 class="display-3 blog-title">
            MRA Referral Program Terms and Conditions
          </h1>
        </v-layout>
    </v-container>
  </div>
  <v-container grid-list-xl pt-0>
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10 class="">
        <v-breadcrumbs class="pxsnap-0" :items="items" divider=">"></v-breadcrumbs>
      </v-flex>
      <v-flex md8 sm9 xs10>
        Last updated: April 17, 2019
      </v-flex>
      <v-flex md8 sm9 xs10 >
        <div>
          The MRA Referral Program (“The Program”) allows Members to earn money by referring friends to book their room on MRA.
        </div>
      </v-flex>
      <v-flex md8 sm9 xs10 >
        <div>
          To participate, Members must agree to these terms, which become part of the MRA Terms of Service.  Capitalized terms that are not defined here will have the same meaning given to them in the MRA Terms of Service.
        </div>
      </v-flex>
    </v-layout>
    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">How to Earn Referral Credits</h3>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          Members can earn money if: a referred friend uses their referral code when renting a room on MRA. The code is only considered as USED once the booking request has been confirmed, the money has reached MRA’s bank account and if the referred friend stays in the property for the entire length of the contract. If the referred friend breaks the contract or decides to cancel the booking, the discount code is not considered as “used” and the member will not be entitled any money.
        </div>
      </v-flex>
    </v-layout>
    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">Qualifying Reservations</h3>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          The Qualifying Reservation must be booked and paid for via the MRA platform, and the referred friend must complete the stay or the experience before the referring Member can receive the money. A reservation will not be considered a Qualifying Reservation, and therefore no money will be earned, if the reservation is cancelled at any time.
        </div>
      </v-flex>
    </v-layout>
    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">Redeeming the money</h3>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          The money may only be redeemed via the MRA Platform. Once earned, the money will appear on your Dashboard. The money must be claimed within one year from the date they are issued, after which they will expire. You are only entitled to withdraw the money when you are legally allowed to. My Room Abroad does not provide working permits nor employee benefits.
        </div>
        <div class="">
          If for any reason you believe that there is a discrepancy regarding your balance of referral money, please contact us. MRA may require you to submit additional information in order to make a determination regarding your balance. All decisions regarding your balance will be final and at MRA’s sole discretion.
        </div>
        <div class="">
          You are responsible for any tax consequences and reporting, if any, that may result from your redemption or use of the money.
        </div>
      </v-flex>
    </v-layout>
    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">Referred Friends</h3>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          Referred friends who have use a valid coupon code will also receive credit in the form of a coupon or coupons in the amount(s) indicated in the referral invitation or accompanying promotional materials that can be used on their next Qualifying Reservation on MRA. Coupon codes that are not automatically applied must be entered during checkout. Coupon codes may not be combined with other MRA referral programs or incentives. Each coupon code may only be used on www.myroomabroad.com in conjunction with the booking of a single eligible property that meets the minimum total value (excluding guest fees or taxes) indicated in the referral invitation, accompanying promotional materials or MRA website.
        </div>
        <div class="">
          MRA reserves the right to any remedy, including cancellation of your account or reservations, if fraud, tampering, violations of these terms or MRA’s Terms of Service or Payments Terms of Service, or technical errors are suspected. Void where prohibited. If your reservation later qualifies for a refund, the maximum refund you may receive is the amount you actually paid.
        </div>
      </v-flex>
    </v-layout>
    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">Multiple Referrals</h3>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          A referred friend may only use one discount code. If a referred friend receives discount codes from multiple Members, only the Member associated with the discount code actually used by the referred friend will receive money.
        </div>
        <div class="">
          <span class="font-weight-bold">The Program may not be combined with other MRA referral programs or incentives.</span> If a referred friend receives referral links from multiple MRA referral programs, preference will be given to the discount code used.
        </div>
      </v-flex>
    </v-layout>

    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">Severability</h3>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          If any provision in these terms are held to be invalid, void, or unenforceable, such provision (or the part of it that is making it invalid, void or unenforceable) will be struck and not affect the validity of and enforceability of the remaining provisions.
        </div>
      </v-flex>
    </v-layout>
    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">Termination and Changes</h3>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          MRA may suspend or terminate the Program or a user’s ability to participate in it at any time for any reason.
        </div>
        <div class="">
          We reserve the right to suspend accounts or remove earned money if we notice any activity that we believe is abusive, fraudulent, or in violation of the MRA Terms of Service or Payments Terms of Service. We reserve the right to review and investigate all referral activities and to suspend accounts or modify referrals in our sole discretion as deemed fair and appropriate.
        </div>
        <div class="">
          The scope, variety, and type of services and products that you may obtain by redeeming the money earned can change at any time.
        </div>
      </v-flex>
    </v-layout>
    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">Updates to the terms</h3>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          We can update these terms at any time without prior notice. If we modify these terms, we will post the modification on the Myroomabroad.com website, applications, or services, which are effective upon posting. Continued participation in the Program after any modification shall constitute consent to such modification.
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import axios from 'axios'
import * as types from '../../store/types'
import moment from 'moment'
import config from '../../config'

export default {
  name: "",
  data: () => ({
    items:[
      {
         text: 'Homepage',
         disabled: false,
         href: '/'
       },{
         text: 'Referral program',
         disabled: false,
         href: 'referral'
       },
       {
         text: 'Terms & Conditions',
         disabled: false,
         href: 'referralTerms'
       },
     ]
  }),
  methods: {
  },
  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/family.jpg") center/cover no-repeat;
}
.blog-title {
    padding-top: 15vh;
    margin-bottom: 10vh;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.about-us div {
    margin: 1rem 0;
    text-align: justify;
}
.family{
  width:90%;
}
.family {
    width: 90%;
    margin: auto;
}
.justify {
  text-align: justify;
}
.nomargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
</style>
